import { useEffect } from "react";
import * as Axios from "axios";
import TimerPanel from "../components/TimerPanel";
import { Button, Card, Container, Form, Row, Image } from "react-bootstrap";
import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { setDataStorage, getDataStorage } from "../hook/DataStore";
import "react-h5-audio-player/lib/styles.css";
import ReactLoading from "react-loading";
import SweetAlert from "react-bootstrap-sweetalert";
import "../assets/css/global.css";
import Vocal from "@untemps/react-vocal";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import "../App.css";
import $ from "jquery";

const styles = {
  rhap_container: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
};
var Api_req_url = "https://bot.jagobahasa.com/";

function ExamScreen(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState({
    show: false,
    label: "",
    type: "spinningBubbles",
  });
  const [alert, setAlertMessage] = useState({
    show: false,
    type: "success",
    label: "",
    text: "",
    confirmation: () => {},
  });
  const [inStep, setInStep] = useState(
    getDataStorage("dataExamp", "inStep", "/"),
  );
  const [indexExamp, setIndexExamp] = useState(
    getDataStorage("dataExamp", "indexExamp", {
      no: 1,
      type: "reading",
      soal_untuk: "jago bahasa",
    }),
  );
  const [listAnswer, setListAnswer] = useState(
    getDataStorage("dataExamp", "listAnswer", {
      reading: [],
      listening: [],
      listening_essay: [""],
      reading_essay: [""],
    }),
  );
  const [timeTotal, setTimeTotal] = useState(
    getDataStorage("dataExamp", "timeTotal", 540000),
  );
  const [audio_in, setAudioin] = useState(
    parseInt(getDataStorage("dataExamp", "try_pay")) < 200 ? true : true,
  );
  const [Cekjawaban, setCekjawaban] = useState("");

  const [dataForm, setDataForm] = useState(
    getDataStorage("dataExamp", "registerForm", {
      nama: null,
      no_hp: null,
      hobby: null,
    }),
  );
  // const [timeTotal, setTimeTotal] = useState(10000);
  const [result, setResult] = useState("");
  const [halaman, setHalaman] = useState(() => {
    // Inisialisasi halaman dari localStorage, atau default ke 1 jika belum ada
    return parseInt(localStorage.getItem("halaman")) || 1;
  });
  const [total_soal_all, setTotal_soal_all] = useState(0);
  const _onVocalStart = () => {
    setResult();
  };

  const _onVocalResult = (result) => {
    setResult(result);
  };
  const [dataExamp, setDataExamp] = useState([]);

  useEffect(() => {
    if (inStep !== "exam") {
      navigate(inStep);
    }
  }, []);
  useEffect(() => {
    setLoading((prevState) => ({
      ...prevState,
      show: true,
      label: "Loading questions ...",
      type: "spinningBubbles",
    }));
    Axios.default
      .get(
        Api_req_url +
          `api/jb_assesment/exam/${indexExamp.type}/${indexExamp.no}/${indexExamp.soal_untuk}`,
      )
      .then((res) => {
        setDataExamp(res.data);
        // console.log(res.data);
        if (total_soal_all === 0) {
          setTotal_soal_all(res.data[0].total_soal_all);
        }
        setLoading((prevState) => ({
          ...prevState,
          show: false,
        }));
      })
      .catch((err) => {
        setLoading((prevState) => ({
          ...prevState,
          show: false,
        }));
        console.log(err);
      });
  }, [indexExamp]);

  function try_play_on() {
    let try_play = parseInt(getDataStorage("dataExamp", "try_pay"));

    if (try_play > 10) {
      setAudioin(true);
    }
    if (try_play < 20) {
      setDataStorage("dataExamp", "try_pay", try_play + 1);
    }
  }

  function nextExamp() {
    if (halaman < total_soal_all) {
      const newHalaman = halaman + 1;
      setHalaman(newHalaman);
      localStorage.setItem("halaman", newHalaman);
    }
    setDataStorage("dataExamp", "try_pay", 0);
    setAudioin(
      parseInt(getDataStorage("dataExamp", "try_pay")) < 20 ? true : true,
    );
    if (indexExamp.no < dataExamp[0].total_soal) {
      if (dataExamp[0].kategori_soal == "speaking") {
        let arr_list_speaking = listAnswer.speaking;
        arr_list_speaking[indexExamp.no - 1] = result;
        setResult("");
      }

      if (Cekjawaban == undefined) {
        switch (dataExamp[0].kategori_soal) {
          case "reading":
            setAlertMessage({
              show: true,
              type: "danger",
              label: "Jawaban tidak boleh kosong",
              text: "Silahkan isi jawaban dahulu sebelum ke soal selanjutnya",
              confirmation: () =>
                setAlertMessage((dt) => ({ ...dt, show: false })),
            });
            break;
          case "reading_essay":
            if (
              localStorage.getItem("cekjawaban") === null ||
              localStorage.getItem("cekjawaban") === ""
            ) {
              localStorage.removeItem("cekjawaban");
              setAlertMessage({
                show: true,
                type: "danger",
                label: "Jawaban tidak boleh kosong",
                text: "Silahkan isi jawaban dahulu sebelum ke soal selanjutnya",
                confirmation: () =>
                  setAlertMessage((dt) => ({ ...dt, show: false })),
              });
            } else {
              localStorage.removeItem("cekjawaban");
              let indexsamp = Object.assign(indexExamp, {
                ["no"]: indexExamp.no + 1,
              });
              setDataStorage("dataExamp", "listAnswer", listAnswer);
              setDataStorage("dataExamp", "indexExamp", indexsamp);
              setIndexExamp((prevState) => ({
                ...prevState,
                no: indexsamp.no,
              }));
            }
            break;
          case "listening_essay":
            if (
              localStorage.getItem("cekjawaban") === null ||
              localStorage.getItem("cekjawaban") === ""
            ) {
              localStorage.removeItem("cekjawaban");
              setAlertMessage({
                show: true,
                type: "danger",
                label: "Jawaban tidak boleh kosong",
                text: "Silahkan isi jawaban dahulu sebelum ke soal selanjutnya",
                confirmation: () =>
                  setAlertMessage((dt) => ({ ...dt, show: false })),
              });
            } else {
              localStorage.removeItem("cekjawaban");
              let indexsamp = Object.assign(indexExamp, {
                ["no"]: indexExamp.no + 1,
              });
              setDataStorage("dataExamp", "listAnswer", listAnswer);
              setDataStorage("dataExamp", "indexExamp", indexsamp);
              setIndexExamp((prevState) => ({
                ...prevState,
                no: indexsamp.no,
              }));
            }
            break;
        }
      } else {
        localStorage.removeItem("cekjawaban");
        let indexsamp = Object.assign(indexExamp, {
          ["no"]: indexExamp.no + 1,
        });
        setDataStorage("dataExamp", "listAnswer", listAnswer);
        setDataStorage("dataExamp", "indexExamp", indexsamp);
        setIndexExamp((prevState) => ({
          ...prevState,
          no: indexsamp.no,
        }));
      }
    } else {
      switch (dataExamp[0].kategori_soal) {
        case "reading":
          if (Cekjawaban == undefined) {
            setAlertMessage({
              show: true,
              type: "danger",
              label: "Jawaban tidak boleh kosong",
              text: "Silahkan isi jawaban dahulu sebelum ke soal selanjutnya",
              confirmation: () =>
                setAlertMessage((dt) => ({ ...dt, show: false })),
            });
          } else {
            setIndexExamp((prevState) => ({
              ...prevState,
              no: 1,
              type: "reading_essay",
            }));
            setDataStorage("dataExamp", "indexExamp", {
              no: 1,
              type: "reading_essay",
              soal_untuk: "jago bahasa",
            });
          }

          break;

        case "reading_essay":
          if (
            localStorage.getItem("cekjawaban") === null ||
            localStorage.getItem("cekjawaban") === ""
          ) {
            setAlertMessage({
              show: true,
              type: "danger",
              label: "Jawaban tidak boleh kosong",
              text: "Silahkan isi jawaban dahulu sebelum ke soal selanjutnya",
              confirmation: () =>
                setAlertMessage((dt) => ({ ...dt, show: false })),
            });
          } else {
            setIndexExamp((prevState) => ({
              ...prevState,
              no: 1,
              type: "listening_essay",
            }));
            setDataStorage("dataExamp", "indexExamp", {
              no: 1,
              type: "listening_essay",
              soal_untuk: "jago bahasa",
            });
          }
          break;

        case "listening_essay":
          if (
            localStorage.getItem("cekjawaban") === null ||
            localStorage.getItem("cekjawaban") === ""
          ) {
            setAlertMessage({
              show: true,
              type: "danger",
              label: "Jawaban tidak boleh kosong",
              text: "Silahkan isi jawaban dahulu sebelum ke soal selanjutnya",
              confirmation: () =>
                setAlertMessage((dt) => ({ ...dt, show: false })),
            });
          } else {
            setLoading((prevState) => ({
              ...prevState,
              show: true,
              label: "Mengirim jawaban assessment",
              type: "spin",
            }));
            let arr_list_speaking = listAnswer;
            arr_list_speaking[indexExamp.no] = result;
            setDataStorage("dataExamp", "listAnswer", listAnswer);

            Axios.default
              .post("https://bot.jagobahasa.com/api/jb_assesment/exam/post", {
                ...dataForm,
                jawaban: listAnswer,
              })
              .then((res) => {
                setDataStorage("dataExamp", "score", {
                  correction: res.data.data.koreksi,
                  // answer_key: res.data.data.kunci_jawaban,
                  cs: {
                    nama: res.data.data.cs.cs,
                    no_phone: res.data.data.cs.wa,
                  },
                });
                if (getDataStorage("dataExamp", "register") == "register") {
                  setDataStorage("dataExamp", "inStep", "/score");
                  setInStep("/score");
                  navigate("/score");
                } else {
                  setDataStorage("dataExamp", "inStep", "/score/group");
                  setInStep("/score/group");
                  navigate("/score/group");
                }

                setLoading((prevState) => ({
                  ...prevState,
                  show: false,
                }));
              })
              .catch((err) => {
                setAlertMessage({
                  show: true,
                  type: "danger",
                  label: "Gagal Mengirim",
                  text: "Terkendala dalam mengirimkan jawaban, coba kirim kembali",
                  confirmation: () =>
                    setAlertMessage((dt) => ({ ...dt, show: false })),
                });
                setLoading((prevState) => ({
                  ...prevState,
                  show: false,
                }));
              });
          }
          break;
      }
    }
  }

  function Finish(params) {
    setLoading((prevState) => ({
      ...prevState,
      show: true,
      label: "Mengirim jawaban assessment",
      type: "spin",
    }));
    let arr_list_speaking = listAnswer;
    arr_list_speaking[indexExamp.no] = result;
    setDataStorage("dataExamp", "listAnswer", listAnswer);

    Axios.default
      .post("https://bot.jagobahasa.com/api/jb_assesment/exam/post", {
        ...dataForm,
        jawaban: listAnswer,
      })
      .then((res) => {
        setDataStorage("dataExamp", "score", {
          correction: res.data.data.koreksi,
          // answer_key: res.data.data.kunci_jawaban,
          cs: {
            nama: res.data.data.cs.cs,
            no_phone: res.data.data.cs.wa,
          },
        });
        if (getDataStorage("dataExamp", "register") == "register") {
          setDataStorage("dataExamp", "inStep", "/score");
          setInStep("/score");
          navigate("/score");
        } else {
          setDataStorage("dataExamp", "inStep", "/score/group");
          setInStep("/score/group");
          navigate("/score/group");
        }

        setLoading((prevState) => ({
          ...prevState,
          show: false,
        }));
      })
      .catch((err) => {
        setAlertMessage({
          show: true,
          type: "danger",
          label: "Gagal Mengirim",
          text: "Terkendala dalam mengirimkan jawaban, coba kirim kembali",
          confirmation: () => setAlertMessage((dt) => ({ ...dt, show: false })),
        });
        setLoading((prevState) => ({
          ...prevState,
          show: false,
        }));
      });
  }

  var Exampview = (props) => {
    const [answer, setAnswer] = useState(
      listAnswer[props.exam_data.kategori_soal][props.exam_data.soal_ke - 2],
    );
    setCekjawaban(answer);

    var collectAnswer = (valinput, indexAnswer = 0) => {
      switch (props.exam_data.kategori_soal) {
        case "reading":
          let arr_list_read = listAnswer.reading;
          arr_list_read[props.exam_data.soal_ke - 2] = valinput;
          setAnswer(valinput);

          setDataStorage("dataExamp", "listAnswer", listAnswer);
          break;

        case "reading_essay":
          let arr_list_read_essay = listAnswer.reading_essay;
          if (!arr_list_read_essay[props.exam_data.soal_ke - 2]) {
            arr_list_read_essay[props.exam_data.soal_ke - 2] = "";
          }
          let ans_read =
            arr_list_read_essay[props.exam_data.soal_ke - 2].split("||");
          ans_read[indexAnswer] = valinput;
          valinput = ans_read.join("||");

          arr_list_read_essay[props.exam_data.soal_ke - 2] = valinput;
          // setAnswer(listAnswer.reading_essay);

          localStorage.setItem("cekjawaban", valinput);
          setDataStorage("dataExamp", "listAnswer", listAnswer);
          break;

        case "listening_essay":
          let arr_list_listen_essay = listAnswer.listening_essay;
          if (!arr_list_listen_essay[props.exam_data.soal_ke - 2]) {
            arr_list_listen_essay[props.exam_data.soal_ke - 2] = "";
          }
          let ans =
            arr_list_listen_essay[props.exam_data.soal_ke - 2].split("||");
          ans[indexAnswer] = valinput;
          valinput = ans.join("||");

          arr_list_listen_essay[props.exam_data.soal_ke - 2] = valinput;
          // setAnswer(listAnswer.listening_essay);

          localStorage.setItem("cekjawaban", valinput);
          setDataStorage("dataExamp", "listAnswer", listAnswer);
          break;
      }
    };

    switch (dataExamp[0].kategori_soal) {
      case "reading":
        return (
          <>
            <h6 style={{ marginBottom: 20 }}>
              {props.exam_data.perintah_soal !== null ? (
                <b style={{ color: "#ee572f" }}>
                  {props.exam_data.perintah_soal}
                </b>
              ) : (
                <div></div>
              )}
            </h6>

            <div style={{ display: "flex", justifyContent: "center" }}>
              {props.exam_data.gambar_soal !== null &&
              props.exam_data.gambar_soal !== "null" &&
              props.exam_data.gambar_soal !== "" ? (
                <Image
                  src={props.exam_data.gambar_soal}
                  style={{ width: 300, height: 150, margin: 20 }}
                />
              ) : (
                <div></div>
              )}
            </div>

            <Card.Title style={{ textAlign: "center" }}>
              <div style={{ whiteSpace: "pre-wrap" }} id="str">
                {props.exam_data.soal}
              </div>
            </Card.Title>
            <Row className="mt-5">
              {props.exam_data.list_jawaban.map((item, index) => {
                if (answer == index + 1) {
                  return (
                    <Card.Text
                      key={"list_key" + index}
                      onClick={() => collectAnswer(index + 1)}
                    >
                      <Button
                        style={{
                          backgroundColor: "#ff8100",
                          boxShadow: "none",
                          border: "none",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "20px" }}>
                            <FaCheck size="1.5em" color="white" />
                          </div>
                          <div style={{ width: "100%", color: "white" }}>
                            {item.idx}
                          </div>
                        </div>
                      </Button>
                    </Card.Text>
                  );
                } else {
                  return (
                    <Card.Text
                      key={"list_key" + index}
                      onClick={() => collectAnswer(index + 1)}
                    >
                      <Button variant="light">
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "20px" }}></div>
                          <div style={{ width: "100%" }}>{item.idx}</div>
                        </div>
                      </Button>
                    </Card.Text>
                  );
                }
              })}
            </Row>
          </>
        );

      case "reading_essay":
        let list_inp_read = [];
        for (let i = 0; i < parseInt(props.exam_data.list_jawaban); i++) {
          list_inp_read.push(
            <Form.Group
              key={"lb" + i}
              className="mb-3"
              controlId="exampleForm.ControlTextarea2"
              style={{ width: "200px" }}
            >
              <Form.Label>
                answer {parseInt(props.exam_data.list_jawaban) > 1 ? i + 1 : ""}{" "}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(val) => {
                  collectAnswer(val.target.value, i);
                }}
                oninput={(val) => {
                  val.target.value = val.target.value
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*?)\..*/g, "$1");
                }}
              />
            </Form.Group>,
          );
        }
        return (
          <>
            <h6 style={{ marginBottom: 20 }}>
              {props.exam_data.perintah_soal !== null ? (
                <b style={{ color: "#ee572f" }}>
                  {props.exam_data.perintah_soal}
                </b>
              ) : (
                <div></div>
              )}
            </h6>

            <div style={{ display: "flex", justifyContent: "center" }}>
              {props.exam_data.gambar_soal !== null &&
              props.exam_data.gambar_soal !== "null" &&
              props.exam_data.gambar_soal !== "" ? (
                <Image
                  src={props.exam_data.gambar_soal}
                  style={{ width: 300, height: 150, margin: 20 }}
                />
              ) : (
                <div></div>
              )}
            </div>
            <Card.Title style={{ textAlign: "center" }}>
              <div style={{ whiteSpace: "pre-wrap" }} id="str">
                {props.exam_data.soal}
              </div>
            </Card.Title>
            <Row className="mt-5" style={{ justifyContent: "center" }}>
              {list_inp_read}
              {/* {props.exam_data.list_jawaban.map((item, index) => {
                                if (answer == index + 1) {
                                    return <Card.Text key={"list_key" + index} onClick={() => collectAnswer(index + 1)}><Button style={{ backgroundColor: "#ff8100", boxShadow: "none", border: "none" }}><div style={{ display: "flex" }}><div style={{ width: "20px" }}><FaCheck size="1.5em" color="white" /></div><div style={{ width: "100%", color: "white" }}>{item.idx}</div></div></Button></Card.Text>
                                } else {
                                    return <Card.Text key={"list_key" + index} onClick={() => collectAnswer(index + 1)}><Button variant="light"><div style={{ display: "flex" }}><div style={{ width: "20px" }}></div><div style={{ width: "100%" }}>{item.idx}</div></div></Button></Card.Text>
                                }
                            })} */}
            </Row>
          </>
        );
        break;
      case "listening_essay":
        let list_inp = [];
        for (let i = 0; i < parseInt(props.exam_data.list_jawaban); i++) {
          list_inp.push(
            <Form.Group
              key={"lb" + i}
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
              style={{ width: "200px" }}
            >
              <Form.Label>
                answer {parseInt(props.exam_data.list_jawaban) > 1 ? i + 1 : ""}{" "}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(val) => {
                  collectAnswer(val.target.value, i);
                }}
                oninput={(val) => {
                  val.target.value = val.target.value
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*?)\..*/g, "$1");
                }}
              />
            </Form.Group>,
          );
        }
        return (
          <>
            <h6 style={{ marginBottom: 20 }}>
              {props.exam_data.perintah_soal !== null ? (
                <b style={{ color: "#ee572f" }}>
                  {props.exam_data.perintah_soal}
                </b>
              ) : (
                <div></div>
              )}
            </h6>

            <div style={{ display: "flex", justifyContent: "center" }}>
              {props.exam_data.gambar_soal !== null &&
              props.exam_data.gambar_soal !== "null" &&
              props.exam_data.gambar_soal !== "" ? (
                <Image
                  src={props.exam_data.gambar_soal}
                  style={{ width: 300, height: 150, margin: 20 }}
                />
              ) : (
                <div></div>
              )}
            </div>

            <div>
              <audio
                controlsList="nodownload"
                controls
                onPlay={() => try_play_on()}
              >
                {audio_in && (
                  <source src={props.exam_data.soal} type="audio/mpeg" />
                )}
              </audio>
            </div>
            <Card.Title style={{ marginTop: 20, textAlign: "center" }}>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {props.exam_data.soal_text}
              </div>
            </Card.Title>
            <Row className="mt-5" style={{ justifyContent: "center" }}>
              {list_inp}

              {/* {props.exam_data.list_jawaban.map((item, index) => {
                                if (answer == index + 1) {
                                    return <Card.Text key={"list_key" + index} onClick={() => collectAnswer(index + 1)}><Button style={{ backgroundColor: "#ff8100", boxShadow: "none", border: "none" }}><div style={{ display: "flex" }}><div style={{ width: "20px" }}><FaCheck size="1.5em" color="white" /></div><div style={{ width: "100%", color: "white" }}>{item.idx}</div></div></Button></Card.Text>
                                } else {
                                    return <Card.Text key={"list_key" + index} onClick={() => collectAnswer(index + 1)}><Button variant="light"><div style={{ display: "flex" }}><div style={{ width: "20px" }}></div><div style={{ width: "100%" }}>{item.idx}</div></div></Button></Card.Text>
                                }
                            })} */}
            </Row>
          </>
        );
        break;
    }
  };

  const handleCopy = (e) => {
    e.preventDefault(); // Mencegah aksi salin (copy)
    // Menyisipkan teks "harus jujur" ke clipboard
    e.clipboardData.setData("text/plain", "tidak boleh copy dan paste");
  };

  // Fungsi untuk mencegah klik kanan
  const handleContextMenu = (e) => {
    e.preventDefault(); // Mencegah aksi klik kanan (context menu)
  };

  return (
    <div
      style={{ background: "#f39631", height: "100vh" }}
      className="no-translate"
      translate="no"
      onCopy={handleCopy}
      onContextMenu={handleContextMenu}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 1000,
          top: "58px",
          left: 0,
          backgroundColor: "rgb(255 255 255 / 92%)",
          flexDirection: "column",
          height: "calc(100vh - 58px)",
          width: "100vw",
          display: loading.show == true ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h4 style={{ marginBottom: "30px" }}>{loading.label}</h4>
        <ReactLoading
          type={loading.type}
          color={"#fbba04"}
          width={100}
          height={100}
        />
      </div>
      <SweetAlert
        type={alert.type}
        title={alert.label}
        onConfirm={alert.confirmation}
        show={alert.show}
      >
        {alert.text}
      </SweetAlert>
      {dataExamp[0] && (
        <>
          {/* <TimerPanel time={timeTotal} finishTimer={(timer) => { nextSession() }} progress={((indexExamp.no - 1) / dataExamp[0].total_soal) * 100} session={dataExamp[0].kategori_soal} ></TimerPanel> */}

          <Container
            style={{
              paddingTop: "160px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "bold",
                  color: "white",
                  marginBottom: "10px",
                }}
              >
                Soal Ke : {halaman} / {total_soal_all}
              </div>

              {halaman == total_soal_all ? null : (
                <Button
                  variant="success"
                  style={{
                    width: "150px",
                    padding: "10px",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    Finish();
                  }}
                >
                  FINISH
                </Button>
              )}
            </div>

            <Row className="p-2">
              <Card
                className="text-center examps"
                style={{
                  backgroundColor: "rgb(255 255 255 / 59%)",
                  border: "none",
                  borderRadius: "10px",
                }}
              >
                <Card.Body
                  style={{
                    padding: "30px",
                    overflowY: "scroll",
                    maxHeight: "514px",
                  }}
                >
                  <Exampview exam_data={dataExamp[0]} />
                </Card.Body>
              </Card>
            </Row>
            <Row
              style={{
                display: "flex",
                padding: "12px",
                justifyContent: "right",
              }}
            >
              {halaman == total_soal_all ? (
                <Button
                  variant="light"
                  style={{
                    width: "150px",
                    padding: "10px",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    nextExamp();
                  }}
                >
                  Finish
                </Button>
              ) : (
                <Button
                  variant="light"
                  style={{
                    width: "150px",
                    padding: "10px",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    nextExamp();
                  }}
                >
                  Next
                </Button>
              )}
            </Row>
          </Container>
        </>
      )}
    </div>
  );
}

export default ExamScreen;