import * as Axios from 'axios';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import 'react-h5-audio-player/lib/styles.css';
import ReactLoading from "react-loading";
import SweetAlert from 'react-bootstrap-sweetalert';
import '../assets/css/global.css';
import OtpInput from 'react-otp-input';
import { FiEdit3 } from "react-icons/fi";
import { getDataStorage, setDataStorage } from '../hook/DataStore';
import { useNavigate } from 'react-router-dom';

function OtpScreen(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState({ show: false, label: '', type: 'spinningBubbles' });
    const [alert, setAlertMessage] = useState({ show: false, type: 'success', label: '', text: '', confirmation: () => { } })
    const [otp, setOtp] = useState('');
    const [show, setShow] = useState(false);
    const [inStep, setInStep] = useState(getDataStorage('dataExamp', 'inStep', '/'));
    const [dataForm, setDataForm] = useState(getDataStorage('dataExamp', 'registerForm', {
        nama: null,
        no_hp: null,
        hobby: null
    }));
    const handleClose = () => setShow(false);
    const handleShow = () => { setNowA(dataForm.no_hp); setShow(true); }
    const [noWA, setNowA] = useState(dataForm.no_hp);



    function konfirmasiOTP() {
        setLoading((prevState) => ({
            ...prevState,
            "show": true,
            "label": "Konfirmasi OTP",
            "type": 'spin'
        }));
        Axios.default.post('https://bot.jagobahasa.com/api/jb_assessment_otp_confirm', {
            nama: dataForm.nama,
            no_hp: dataForm.no_hp,
            hobby: dataForm.hobby,
            otp_code: otp,
        }).then((res) => {
            if (res.data.status == true) {

                setAlertMessage({ show: true, type: 'success', label: 'Konfirmasi OTP', text: 'Kode yang dimasukan benar, Silahkan menekan tombol untuk memulai soal', confirmation: () => { setAlertMessage(((dt) => ({ ...dt, "show": false }))); setDataStorage('dataExamp', 'inStep', '/exam'); setInStep('/exam'); navigate('/exam'); } })
            } else {
                setAlertMessage({ show: true, type: 'danger', label: 'Konfirmasi OTP', text: 'Kode yang dimasukan tidak benar, Silahkan mengisi kode OTP yang benar', confirmation: () => { setAlertMessage(((dt) => ({ ...dt, "show": false }))) } })
            }
            setLoading((prevState) => ({
                ...prevState,
                "show": false
            }));

        }).catch((err) => {
            setAlertMessage({ show: true, type: 'danger', label: 'Gagal Mengirim', text: 'Terkendala dalam mengirimkan pendaftaran, coba kirim kembali', confirmation: () => setAlertMessage(((dt) => ({ ...dt, "show": false }))) })
            setLoading((prevState) => ({
                ...prevState,
                "show": false
            }));
        })
    }

    useEffect(() => {
        if (inStep !== 'otp') {
            navigate(inStep);
        }
    }, [])

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
            <div style={{ position: 'fixed', zIndex: 1000, top: '58px', left: 0, backgroundColor: 'rgb(255 255 255 / 92%)', flexDirection: 'column', height: 'calc(100vh - 58px)', width: '100vw', display: (loading.show == true ? 'flex' : 'none'), justifyContent: 'center', alignItems: 'center' }}>
                <h4 style={{ marginBottom: '30px' }}>{loading.label}</h4>
                <ReactLoading type={loading.type} color={'#fbba04'} width={100} height={100} />

            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Pengubahan Penerima</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="otp">

                            <Form.Control value={noWA} type="number" className='noSpin' placeholder="Nomor WA penerima" onChange={(val) => { setNowA(val.target.value) }} />
                            {/* {(!dataForm.nama && alertValid) && <span className="text-danger">Nama tidak boleh kosong</span>} */}
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={() => { setDataForm((prevState) => ({ ...prevState, 'no_hp': noWA })); handleClose(); setTimeout(() => { setDataStorage('dataExamp', 'registerForm', dataForm); }, 1000) }}>
                        Simpan Perubahan
                    </Button>
                </Modal.Footer>
            </Modal>


            <SweetAlert
                type={alert.type}
                title={alert.label}
                onConfirm={alert.confirmation}
                show={alert.show}
            >
                {alert.text}
            </SweetAlert>
            <Card style={{ width: '80vw',opacity:"0.9",borderRadius:"20px"  }}>

                <Card.Body className='p-5'>
                    <Card.Title style={{ textAlign: 'center', margin: 0 }}>OTP Assessment</Card.Title>
                    <Card.Title style={{ marginBottom: '30px', textAlign: 'center' }}><span className="text-danger" style={{ fontSize: 15 }}>OTP dikirimkan ke nomor ({dataForm.no_hp})<FiEdit3 onClick={() => { handleShow() }} style={{ marginLeft: 10, color: 'black', backgroundColor: 'orange', padding: 5, height: 25, width: 25, borderRadius: 10, cursor: 'pointer' }}></FiEdit3></span></Card.Title>

                    <Form>
                        <Form.Group className="mb-3" controlId="otp">
                            <OtpInput
                                value={otp}
                                onChange={(val) => { setOtp(val) }}
                                numInputs={5}
                                separator={<span style={{ marginLeft: '2vw', marginRight: '2vw' }}></span>}
                                shouldAutoFocus
                                inputStyle={{ fontSize: 30 }}
                                containerStyle={{ justifyContent: 'center' }}

                            />
                            {/* <Form.Control type="text" placeholder="Masukan nama" onChange={(val) => { setDataForm((dt) => ({ ...dt, 'nama': val.target.value })); }} />
                            {(!dataForm.nama && alertValid) && <span className="text-danger">Nama tidak boleh kosong</span>} */}
                        </Form.Group>

                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Button onClick={konfirmasiOTP} variant="success" type="button" style={{ width: '80%', height: '60px', marginTop: '25px' }}>
                                Konfirmasi
                            </Button>

                        </div>
                    </Form>

                </Card.Body>
            </Card>

        </div >
    );
}

export default OtpScreen;
