
export const getDataStorage = (keyStorage, keyObject, defaultVal = {}) => {

    if (!localStorage.getItem(keyStorage)) {
        localStorage.setItem(keyStorage, JSON.stringify({}))
    }
    let data_var = JSON.parse(localStorage.getItem(keyStorage))
    if (!data_var.hasOwnProperty(keyObject)) {

        localStorage.setItem(keyStorage, JSON.stringify(Object.assign(data_var, { [keyObject]: defaultVal })))
    }
    return JSON.parse(localStorage.getItem(keyStorage))[keyObject]
}

export const setDataStorage = (keyStorage, keyObject, dataObject) => {
    let data_var = JSON.parse(localStorage.getItem(keyStorage));
    localStorage.setItem(keyStorage, JSON.stringify(Object.assign(data_var, { [keyObject]: dataObject })))

}