import { createRef, useEffect, useState } from "react";
import { getDataStorage, setDataStorage } from "../hook/DataStore";
import { FaHistory } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import { Fireworks } from "@fireworks-js/react";

function ScoreScreen(props) {
  const navigate = useNavigate();
  const ref = createRef(null);
  const [inStep, setInStep] = useState(
    getDataStorage("dataExamp", "inStep", "/"),
  );
  const [score, setScore] = useState(
    getDataStorage("dataExamp", "score", {
      cs: {
        nama: "-",
        no_phone: "-",
      },
      correction: {
        nilai: {
          level: "-",
          listening: "-",
          reading: "-",
          speaking: "-",
          total: 0,
        },
      },
    }),
  );

  const [dataForm, setDataForm] = useState(
    getDataStorage("dataExamp", "registerForm", {
      nama: null,
      no_wa: null,
      hobby: null,
    }),
  );

  function sendScore() {
    html2canvas(document.body).then(function (canvas) {
      fetch("https://bot.jagobahasa.com/api/jb_assesment/send_score", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify({
          ...score,
          ...dataForm,
          total_score: canvas.toDataURL(),
        }),
      })
        .then((response) => response.json())
        .then((response) => {})
        .catch((error) => {
          console.error("Error:", error);
        });
    });

    // setDataStorage('dataExamp', 'inStep', '/');
    // setInStep('/');
    // navigate(inStep);
  }

  function reExam(params) {
    localStorage.clear();
    navigate("/");
  }

  useEffect(() => {
    if (inStep !== "score") {
      navigate(inStep);
    }
    sendScore();
    // Status_send_wa();
  }, []);

  const handleCopy = (e) => {
    e.preventDefault(); // Mencegah aksi salin (copy)
    // Menyisipkan teks "harus jujur" ke clipboard
    e.clipboardData.setData("text/plain", "tidak boleh copy dan paste");
  };

  // Fungsi untuk mencegah klik kanan
  const handleContextMenu = (e) => {
    e.preventDefault(); // Mencegah aksi klik kanan (context menu)
  };

  return (
    <div
      className="no-translate"
      translate="no"
      onCopy={handleCopy}
      onContextMenu={handleContextMenu}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Fireworks
        options={{
          acceleration: 1,
          particles: 20,
          explosion: 2,
          opacity: 0.1,
          gravity: 2,
          mouse: {
            click: false,
            move: false,
            max: 1,
          },
          sound: {
            enabled: true,
            files: [
              "./tiny_rocket.mp3",
              "./explosion0.mp3",
              "./explosion1.mp3",
              "./explosion2.mp3",
            ],
            volume: {
              min: 20,
              max: 20,
            },
          },
          rocketsPoint: {
            min: 0,
            max: 100,
          },
        }}
        style={{
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          position: "fixed",
          background: "transparent",
        }}
      />
      {/* <button style={{ marginBottom: '10px' }} onClick={() => {
                getImage(); console.log(image.replace(/\+/g, '_')
                    .replace(/\//g, '-')
                    .replace(/=+$/g, ''))
            }}>
                Take screenshot
            </button> */}
      <div
        ref={ref}
        style={{
          backgroundColor: "white",
          width: "80%",
          padding: "10px",
          borderRadius: "25px",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          Final Score
        </h2>
        <center>
          {" "}
          <label>
            <b>Terimakasih</b> telah menyelesaikan assessment{" "}
            <b>{dataForm.nama}</b>, kamu berhasil meraih level
          </label>
        </center>
        <h3
          style={{ textAlign: "center", color: "#f44336", fontWeight: "bold" }}
        >
          "{score.correction.nilai.level}"
        </h3>
        <center>
          {" "}
          <label>
            dalam <b>assessment</b> kali ini!
          </label>
        </center>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <table style={{ marginTop: 10, width: "250px" }}>
            <thead>
              <tr>
                <th width={"65%"}></th>
                <th width={5}></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* <td><b>Session Speaking</b></td> */}
                {/* <td>:</td> */}
                {/* <td style={{ textAlign: "right" }}>{score.correction.nilai.speaking}</td> */}
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            marginTop: 20,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginTop: 20,
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <button
              onClick={reExam}
              type="button"
              className="btn btn-warning"
              style={{
                width: "200px",
                padding: 15,
                color: "grey",
                fontSize: 12,
                fontWeight: "bold",
                zIndex: 1,
              }}
            >
              <FaHistory size="1em" color="white" style={{ marginRight: 10 }} />
              Mulai Assessment Ulang
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScoreScreen;