import React, { useEffect } from "react";
import * as Axios from "axios";
import { Button, Card, Form } from "react-bootstrap";
import { useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import ReactLoading from "react-loading";
import SweetAlert from "react-bootstrap-sweetalert";
import "../assets/css/global.css";
import { getDataStorage, setDataStorage } from "../hook/DataStore";
import Vocal, { isSupported } from "@untemps/react-vocal";
import { useNavigate, useParams } from "react-router-dom";
function RegisterScreenGroup(props) {
  const { cs } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState({
    show: false,
    label: "",
    type: "spinningBubbles",
  });
  const [alert, setAlertMessage] = useState({
    show: false,
    type: "success",
    label: "",
    text: "",
    confirmation: () => {},
  });
  const [alertValid, setAlertValid] = useState(false);
  const [inStep, setInStep] = useState(
    getDataStorage("dataExamp", "inStep", "/"),
  );
  const [dataForm, setDataForm] = useState(
    getDataStorage("dataExamp", "registerForm", {
      nama: null,
      no_hp: null,
      kota_asal: null,
      lembaga: null,
      hobby: "",
    }),
  );
  const [data, setData] = useState({
    nama: null,
    no_wa: null,
    cs: cs,
  });
  async function checkValidity() {
    setDataStorage("dataExamp", "registerForm", dataForm);
    setDataStorage("dataExamp", "try_pay", 0);
    setDataStorage("dataExamp", "register", "register_group");
    setAlertValid(false);
    if (!dataForm.nama) {
      setAlertValid(true);
    } else if (!dataForm.no_hp) {
      setAlertValid(true);
    } else if (!dataForm.kota_asal) {
      setAlertValid(true);
    } else if (!dataForm.lembaga) {
      setAlertValid(true);
    } else {
      setLoading((prevState) => ({
        ...prevState,
        show: true,
        label: "Registration Process",
        type: "spin",
      }));

      Axios.default
        .post(
          "https://bot.jagobahasa.com/api/jb_assesment/register/group",
          dataForm,
        )
        .then((res) => {
          if (res.status == 200) {
            setDataStorage("dataExamp", "inStep", "/exam");
            setInStep("/exam");
            navigate("/exam");
          }
          setLoading((prevState) => ({
            ...prevState,
            show: false,
          }));
          if (cs !== undefined) {
            Axios.default
              .post("https://bot.jagobahasa.com/api/insert-kontak", data)
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            console.log("no cs");
          }
        })
        .catch((err) => {
          setAlertMessage({
            show: true,
            type: "danger",
            label: "Gagal Mengirim",
            text: "Terkendala dalam mengirimkan pendaftaran, coba kirim kembali",
            confirmation: () =>
              setAlertMessage((dt) => ({ ...dt, show: false })),
          });
          setLoading((prevState) => ({
            ...prevState,
            show: false,
          }));
        });
    }
  }

  function registerAssesment() {
    checkValidity();
  }
  useEffect(() => {
    if (inStep !== "/") {
      navigate(inStep);
    }
  }, []);

  const handleCopy = (e) => {
    e.preventDefault(); // Mencegah aksi salin (copy)
    // Menyisipkan teks "harus jujur" ke clipboard
    e.clipboardData.setData("text/plain", "tidak boleh copy dan paste");
  };

  // Fungsi untuk mencegah klik kanan
  const handleContextMenu = (e) => {
    e.preventDefault(); // Mencegah aksi klik kanan (context menu)
  };

  return (
    <div
        className="no-translate"
        translate="no"
        onCopy={handleCopy}
        onContextMenu={handleContextMenu}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 1000,
          top: "58px",
          left: 0,
          backgroundColor: "rgb(243, 150, 49 / 92%)",
          flexDirection: "column",
          height: "calc(100vh - 58px)",
          width: "100vw",
          display: loading.show == true ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h4 style={{ marginBottom: "30px" }}>{loading.label}</h4>
        <ReactLoading
          type={loading.type}
          color={"#fbba04"}
          width={100}
          height={100}
        />
      </div>

      <SweetAlert
        type={alert.type}
        title={alert.label}
        onConfirm={alert.confirmation}
        show={alert.show}
      >
        {alert.text}
      </SweetAlert>
      <Card
        style={{
          width: "70vw",
          opacity: "0.9",
          borderRadius: "20px",
          "box-shadow":
            " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <Card.Body className="p-4">
          <Card.Title
            style={{
              marginBottom: "20px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            ✨ WELCOME TO JAGO BAHASA ASSESSMENT ✨
          </Card.Title>
          <Card.Title style={{ marginBottom: "30px", textAlign: "center" }}>
            Calon Peserta Pre-Departure Training Kementrian Agama Republik
            Indonesia
          </Card.Title>

          <Form>
            <Form.Group className="mb-3" controlId="nama">
              <Form.Label>Your name :</Form.Label>
              <Form.Control
                defaultValue={dataForm.nama}
                type="text"
                placeholder="Please enter your name"
                onChange={(val) => {
                  setDataForm((dt) => ({ ...dt, nama: val.target.value }));
                  setData((dt) => ({ ...dt, nama: val.target.value }));
                }}
              />
              {!dataForm.nama && alertValid && (
                <span className="text-danger">Name cannot be empty</span>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="no_hp">
              <Form.Label>Phone number (whatsapp):</Form.Label>
              <Form.Control
                defaultValue={dataForm.no_hp}
                className="noSpin"
                type="number"
                placeholder="Example : 085790***"
                onChange={(val) => {
                  setDataForm((dt) => ({ ...dt, no_hp: val.target.value }));
                  setData((dt) => ({ ...dt, no_wa: val.target.value }));
                }}
              />
              {!dataForm.no_hp && alertValid && (
                <span className="text-danger">Number cannot be empty</span>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="kota_asal">
              <Form.Label>Kota Asal:</Form.Label>
              <Form.Control
                defaultValue={dataForm.kota_asal}
                className="noSpin text-uppercase"
                type="text"
                placeholder="Example : KEDIRI"
                onChange={(val) => {
                  const uppercaseValue = val.target.value.toUpperCase(); // Konversi menjadi huruf kapital
                  setDataForm((dt) => ({ ...dt, kota_asal: uppercaseValue }));
                  setData((dt) => ({ ...dt, kota_asal: uppercaseValue }));
                }}
              />
              {!dataForm.kota_asal && alertValid && (
                <span className="text-danger">Kota cannot be empty</span>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="kota_asal">
              <Form.Label>Nama Lembaga:</Form.Label>
              <Form.Control
                defaultValue={dataForm.lembaga}
                className="noSpin"
                type="text"
                placeholder="Example : PERPUSNAS"
                onChange={(val) => {
                  setDataForm((dt) => ({ ...dt, lembaga: val.target.value }));
                  setData((dt) => ({ ...dt, lembaga: val.target.value }));
                }}
              />
              {!dataForm.lembaga && alertValid && (
                <span className="text-danger">Lembaga cannot be empty</span>
              )}
            </Form.Group>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => {
                  registerAssesment();
                }}
                variant="success"
                type="button"
                style={{ width: "90%", height: "60px", marginTop: "25px" }}
              >
                SEND
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default RegisterScreenGroup;