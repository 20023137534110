import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Image, Navbar, Offcanvas } from "react-bootstrap";
import "../src/App.css";
import RegisterScreen from "./screens/RegisterScreen";
import RegisterScreenGroup from "./screens/RegisterScreenGroup";
import Logo from "./assets/images/logo-2.png";
import ExamScreen from "./screens/ExamScreen";
import OtpScreen from "./screens/OtpScreen";
import ScoreScreen from "./screens/ScoreScreen";
import ScoreScreenGroup from "./screens/ScoreScreenGroup";


import { BrowserRouter as Router, Switch, Route, Routes } from "react-router-dom";

function App() {
    const handleContextMenu = (e) => {
        e.preventDefault(); // Mencegah aksi klik kanan (context menu)
    };
	return (
		<>
			<Navbar bg="light" expand={false} style={{ zIndex: "100" }} onContextMenu={handleContextMenu}>
				<Container fluid>
					<Navbar.Brand href="#">
						<Image src={Logo} style={{ width: 100 }} />
					</Navbar.Brand>
					{/* <Navbar.Toggle aria-controls="offcanvasNavbar" /> */}
					<Navbar.Offcanvas id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" placement="start">
						<Offcanvas.Header closeButton>
							<Offcanvas.Title id="offcanvasNavbarLabel">Menu </Offcanvas.Title>
						</Offcanvas.Header>
						<Offcanvas.Body></Offcanvas.Body>
					</Navbar.Offcanvas>
				</Container>
			</Navbar>
			<Router>
				<Routes>
					<Route path="*" element={<RegisterScreen />} />
					<Route path="/" element={<RegisterScreen />} />
    				<Route path="/group" element={<RegisterScreenGroup />} />
					<Route path="/cs/:cs" element={<RegisterScreen />} />
                    <Route path="/group/cs/:cs" element={<RegisterScreenGroup />} /> 
					<Route path="/otp" element={<OtpScreen />} />
					<Route path="/exam" element={<ExamScreen />} />
					<Route path="/score" element={<ScoreScreen />} />
				    <Route path="/score/group" element={<ScoreScreenGroup />} />
				</Routes>
			</Router>
			{/* <RegisterScreen /> */}
		</>
	);
}

export default App;